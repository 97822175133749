import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoAdd from "../../images/carbon_add.svg";
import LogoView from "../../images/Frame 108.svg";
import messageBox from "../../utils/messagebox";
import { getProducts, getAllValueAddedFeatures, getTermsAndConditions, updateValueAddedFeature } from "../../services/Admin.service";
import Concepts from "./Concepts";
import { addQuotes } from "../../services/Quotes.service";
import { putProjectId } from "../../services/Projects.service";
import {ModalValueOffer} from "./ValueOffers/ModalValueOffer";
import { DropDownMenuTerms } from "./ValueOffers/DropDownMenuTerms";

function CreateQuote({
  Context,
  clientId,
  dataClient,
  dataProject,
  projectId,
  setShowModalCot,
  isUpdate,
  setIsUpdate,
  conceptsProps,
  setConceptsProps,
  idQuoteLocalStorage,
  setIdQuoteLocalStorage
}) {
  let navigate = useNavigate();

  let [isLoading, setIsLoading] = useState(true);
  let [products, setProducts] = useState([]);
  let [concepts, setConcepts] = useState(conceptsProps ? conceptsProps : [
    {
      concept: "noData",
      price: 0,
      unit: 0,
      discount: 0,
      imported: 0,
      duration: 0,
      billing: 0,
      reference: "",
      review: "",
      scope: ""
    },
  ]);
  

  let [ref, setRef] = useState("");
  let [review, setReview] = useState("");
  let [titleProject, setTitleProject] = useState("");
  let [m2, setM2] = useState("");
  let [location, setLocation] = useState("");
  let [description, setDescription] = useState("");
  let [scope, setScope] = useState("");
  // Obtener el valor de localStorage y convertirlo a booleano
  const savedIsModalVisible = localStorage.getItem('isModalVisible') === 'true';
  let [isModalVisible, setIsModalVisible] = useState(savedIsModalVisible);
  let [values, setValues] = useState([]);
  // Recuperar el estado guardado del almacenamiento local
  const [selectedValues, setSelectedValues] = useState(() => {
    const savedValues = localStorage.getItem('selectedValues');
    return savedValues ? JSON.parse(savedValues) : [];
  });
  let selectedValuesText = [];
  const [selectedTerms, setSelectedTerms] = useState(() => {
    const savedValues = localStorage.getItem('selectedTerms');
    return savedValues ? JSON.parse(savedValues) : [];
  });

  const savedIsModalTermsVisible = localStorage.getItem('isModalTermsVisible') === 'true';
  let [isModalTermsVisible, setIsModalTermsVisible] = useState(savedIsModalTermsVisible);
  let [terms, setTerms] = useState([]);

  useEffect(() => {
    getProduct();
    getValues();
    getTerms();
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  useEffect(() => {
    if (dataProject) {
      let { reference, review, title, m2, location, description, scope, isModalVisible, values } =
        dataProject;
      if (reference) setRef(reference);
      if (review) setReview(review);
      if (title) setTitleProject(title);
      if (m2) setM2(m2);
      if (location) setLocation(location);
      if (description) setDescription(description);
      if (scope) setScope(scope);
      if(isModalVisible) setIsModalVisible(isModalVisible);
      if(values) getValues();
    }
  }, [dataProject]);

  async function getProduct() {
    await getProducts().then(({ data }) => {
      if (data.error) messageBox.Simple(data.mensaje, "error");
      else {
        setProducts(data);
        setIsLoading(false);
      }
    });
  }

  // Guardar el estado en el almacenamiento local cuando cambie
  useEffect(() => {
    localStorage.setItem('selectedValues', JSON.stringify(selectedValues));
  }, [selectedValues]);

  useEffect(() => {
    localStorage.setItem('selectedTerms', JSON.stringify(selectedTerms));
  }, [selectedTerms]);

  async function getValuesTexts() {
    //console.log("startef");
    try {
      const { data } = await getAllValueAddedFeatures(); // Espera la respuesta del backend
  
      if (data.error) {
        // Si hay un error en la respuesta, muestra un mensaje de error
        messageBox.Simple(data.mensaje, "error");
      } else {
        // Filtra los valores que coinciden con los IDs en selectedValues
        const filtered = data.filter(value => selectedValues.includes(value.id));
  
        // Aquí puedes manejar los datos filtrados como desees
        //console.log(filtered); // Por ejemplo, imprime los valores filtrados en la consola
        selectedValuesText = filtered;
        //console.log("endede");
        // Si necesitas hacer algo más con los valores filtrados, puedes hacerlo aquí
        // Ejemplo: actualizar un estado, enviarlos a otra función, etc.
      }
    } catch (error) {
      // Maneja cualquier error que ocurra durante la solicitud
      console.error('Error fetching value-added features:', error);
      messageBox.Simple("Hubo un error al obtener los datos", "error");
    }
  }

  async function getValues() {
    await getAllValueAddedFeatures().then(({data}) => {
      if(data.error) messageBox.Simple(data.mensaje, "error");
      else {
        setValues(data);
        setIsLoading(false);
      }
    });
  }

  async function getTerms() {
    await getTermsAndConditions().then(({data}) => {
      if(data.error) messageBox.Simple(data.mensaje, "error");
      else {
        setTerms(data);
        setIsLoading(false);
      }
    });
  }

  async function redirectPdf(dataProject, view) {
    if( !dataProject.createDate ) dataProject.createDate = "";
    
    await getValuesTexts();
    //console.log(selectedValuesText);
    console.log("redirect terms: " + selectedTerms);
    dataProject.hubspotOwnerId = sessionStorage.getItem("hubspotOwnerId");
    dataProject.data = concepts;
    navigate("../quotePdf", {
      state: {
        client: dataClient,
        project: dataProject,
        products,
        view,
        concepts: dataProject.data,
        selectedValues: selectedValuesText,
        selectedTerms: selectedTerms
      }
    });
  }

  function handleOnChange() {
    setConcepts([
      ...concepts,
      {
        concept: "noData",
        price: 0,
        unit: 0,
        discount: 0,
        imported: 0,
        duration: 0,
        billing: 0,
        reference: "",
        review: "",
        scope: ""
        
      },
    ]);
  }

  function handleModalValue() {
    const newValue = !isModalVisible;
    setIsModalVisible(newValue);
    getValues();

    // Guardar el nuevo valor en localStorage
    localStorage.setItem('isModalVisible', newValue);
  }

  function handleDeleteModalValue() {
    setIsModalVisible(false);

    // Guardar el nuevo valor en localStorage
    localStorage.setItem('isModalVisible', false);
  }

  function handleModalTerms() {
    const newValue = !isModalTermsVisible;
    setIsModalTermsVisible(newValue);
    getTerms();

    // Guardar el nuevo valor en localStorage
    localStorage.setItem('isModalTermsVisible', newValue);
  }

  function handleDeleteModalTerms() {
    setIsModalTermsVisible(false);

    // Guardar el nuevo valor en localStorage
    localStorage.setItem('isModalTermsVisible', false);
  }

  async function saveQuote(e) {
    e.preventDefault();
    let {
      concept,
      price,
      unit,
      imported,
      duration,
      billing,
      reference,
      review,
      scope,
    } = concepts[0];
    
    if (concept === "noData" || price === 0 || unit === 0 || imported === 0 || duration === 0 || billing === 0 || reference === "" || review === "" || scope === "") {
      return messageBox.Simple("Necesita rellenar al menos un concepto", "error");
    }
  
    let quoteLocal = JSON.parse(localStorage.getItem('quoteSave')) || {};
  
    if (conceptsProps) {
      quoteLocal[`${projectId}`][idQuoteLocalStorage] = { 
        clientId, 
        projectId, 
        userOwnerID: Context.id, 
        Title: dataProject.title, 
        dataClient, 
        fees: concepts, 
        isModalVisible // Asegúrate de que esta línea esté aquí
      };
    } else {
      if (quoteLocal[`${projectId}`]) {
        quoteLocal[`${projectId}`] = [
          ...quoteLocal[`${projectId}`], 
          { 
            clientId, 
            projectId, 
            userOwnerID: Context.id, 
            Title: dataProject.title, 
            dataClient, 
            fees: concepts, 
            isModalVisible // Asegúrate de que esta línea esté aquí
          }
        ];
      } else {
        quoteLocal[`${projectId}`] = [
          { 
            clientId, 
            projectId, 
            userOwnerID: Context.id, 
            Title: dataProject.title, 
            dataClient, 
            fees: concepts, 
            isModalVisible // Asegúrate de que esta línea esté aquí
          }
        ];
      }
    }
    localStorage.setItem('quoteSave', JSON.stringify(quoteLocal));
    messageBox.Simple('Se ha guardado la cotización correctamente.', 'success');
    setTimeout(() => window.location.reload(), 2500);
  }

  async function handleAddQuote(e) {
    e.preventDefault();
    if (isUpdate) {
      await putProjectId(dataProject.id, {
        ref,
        review,
        title: titleProject,
        m2,
        location,
        description,
        scope,
      })
        .then(({ data }) => {
          if (data.error) {
            messageBox.Simple(data.message, "error");
          } else {
            messageBox.Simple(data.message, "success");
            setTimeout(() => window.location.reload(), 2000);
          }
        })
        .catch((err) => messageBox.Simple(err, "error"));
    } else {
      let {
        concept,
        price,
        unit,
        imported,
        duration,
        billing,
        reference,
        review,
        scope,
      } = concepts[0];
      if (concept === "noData")
        return messageBox.Simple(
          "Necesita rellenar al menos un concepto",
          "error"
        );
      if (price === 0)
        return messageBox.Simple(
          "Necesita rellenar al menos un concepto",
          "error"
        );
      if (unit === 0)
        return messageBox.Simple(
          "Necesita rellenar al menos un concepto",
          "error"
        );
      if (imported === 0)
        return messageBox.Simple(
          "Necesita rellenar al menos un concepto",
          "error"
        );
      if (duration === 0)
        return messageBox.Simple(
          "Necesita rellenar al menos un concepto",
          "error"
        );
      if (billing === 0)
        return messageBox.Simple(
          "Necesita rellenar al menos un concepto",
          "error"
        );
      if (reference === "")
        return messageBox.Simple(
          "Necesita rellenar al menos un concepto",
          "error"
        );
      if (review === "")
        return messageBox.Simple(
          "Necesita rellenar al menos un concepto",
          "error"
        );
      if (scope === "")
        return messageBox.Simple(
          "Necesita rellenar al menos un concepto",
          "error"
        );
      await addQuotes({
        clientId,
        projectId,
        userOwnerID: Context.id,
        Title: dataProject.title,
        dataClient,
        dataProject,
        fees: concepts,
      })
        .then(({ data }) => {
          if (data.error) {
            messageBox.Simple(data.message, "error");
          } else {
            messageBox.Simple(data.message, "success");
            if (conceptsProps) {
              let dataLocalStorage = JSON.parse(localStorage.getItem('quoteSave'));
              if (dataLocalStorage) {
                let newLocalStorage = [];
                dataLocalStorage[`${projectId}`].map((quote, index) => idQuoteLocalStorage !== index && newLocalStorage.push(quote));
                dataLocalStorage[`${projectId}`] = newLocalStorage;
                localStorage.setItem('quoteSave', JSON.stringify(dataLocalStorage));
              }
            }
            setTimeout(() => window.location.reload(), 2000);
          }
        })
        .catch((err) => messageBox.Simple(err, "error"));
    }
  }

  return !isLoading && isUpdate ? (
    <div tabIndex="-1" aria-hidden="true" className="content-modal">
      <form className="mx-auto" onSubmit={(e) => e.preventDefault()}>
        <div
          className="col p-3"
          style={{
            backgroundColor: "#F4F4F4",
            borderRadius: "25px",
            position: "relative",
          }}>
          <div className="mx-auto my-2">
            <h4
              style={{
                color: "#004750",
                fontSize: "32px",
                fontWeight: "700",
              }}>
              Cliente
            </h4>
          </div>
          <div className="  lg:flex block ">
            <div className="lg:flex block">
              <div className="block mx-auto">
                <div className="lg:flex block">
                  <div className="mt-2 mx-4">
                    <div className="mb-3">
                      <label className="form-label">Cliente</label>
                      <input
                        type="text"
                        className="form-control text-center"
                        value={`${dataClient.firstName} - ${dataClient.firstLastName}`}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="mt-2 mx-4">
                    <div className="mb-3">
                      <label className="form-label">Contacto</label>
                      <input
                        type="text"
                        className="form-control text-center"
                        value={dataClient.email}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="mt-2 mx-4">
                    <div className="mb-3">
                      <label className="form-label">Referencia</label>
                      <input
                        type="text"
                        className="form-control text-center"
                        value={ref}
                        onChange={(e) => setRef(e.target.value)}
                        disabled={isUpdate ? false : true}
                      />
                    </div>
                  </div>
                  <div className="mt-2 mx-4">
                    <div className="mb-3">
                      <label className="form-label">Revisión</label>
                      <input
                        type="text"
                        className="form-control text-center"
                        value={review}
                        onChange={(e) => setReview(e.target.value)}
                        disabled={isUpdate ? false : true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mx-auto my-3">
            <h4
              style={{
                color: "#004750",
                fontSize: "32px",
                fontWeight: "700",
              }}>
              Proyecto
            </h4>
          </div>
          <div className="lg:flex block ">
            <div className="lg:flex block">
              <div className="block mx-auto">
                <div className="lg:flex block">
                  <div className="mt-2 mx-4">
                    <div className="mb-3">
                      <label className="form-label">ID</label>
                      <input
                        type="text"
                        className="form-control text-center"
                        value={dataProject.id}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="mt-2 mx-4">
                    <div className="mb-3">
                      <label className="form-label">Nombre del proyecto</label>
                      <input
                        type="text"
                        className="form-control text-center"
                        value={titleProject}
                        onChange={(e) => setTitleProject(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mt-2 mx-4">
                    <div className="mb-3">
                      <label className="form-label">m2</label>
                      <input
                        type="text"
                        className="form-control text-center"
                        value={m2}
                        onChange={(e) => setM2(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mt-2 mx-4">
                    <div className="mb-3">
                      <label className="form-label">Ubicación</label>
                      <input
                        type="text"
                        className="form-control text-center"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="lg:grid lg:grid-cols-2 block">
                  <div className="mt-2 ml-4 mr-1">
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Breve descripción
                      </label>
                      <textarea
                        name="textarea"
                        className="form-control text-justify"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="mt-2 mx-4">
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Alcances del servicio a realizar
                      </label>
                      <textarea
                        name="textarea"
                        className="form-control"
                        value={scope}
                        onChange={(e) => setScope(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mx-auto">
            <div className="block mb-10 mx-auto">
              <div className="block gap-2">
                <button
                  type="button"
                  className="btn rounded-pill ColorButtonMain mt-3"
                  onClick={() => {
                    setShowModalCot(false);
                    if (conceptsProps) {
                      setConceptsProps();
                      setIdQuoteLocalStorage();
                    }
                  }}
                >
                  Cancelar
                </button>

                <button
                  type="submit"
                  className="btn rounded-pill ColorButtonMainInv mt-3"
                  onClick={(e) => handleAddQuote(e)}>
                  Actualizar proyecto
                </button>
              </div>
            </div>

            <button
              type="button"
              style={{
                position: "absolute",
                top: "2rem",
                right: "2rem",
              }}
              onClick={() => {
                setShowModalCot(false);
                if (isUpdate) setIsUpdate(false);
                if (conceptsProps) {
                  setConceptsProps();
                  setIdQuoteLocalStorage();
                }
              }}
              title="Cerrar"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm dark:hover:bg-gray-600 dark:hover:text-white">
              <svg className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"></path>
              </svg>
            </button>
          </div>
        </div>
      </form>
    </div>
  ) : (
    <div tabIndex="-1" aria-hidden="true" className="content-modal">
      <form className="mx-auto" onSubmit={(e) => e.preventDefault()}>
        <div
          className="col p-3"
          style={{
            backgroundColor: "#F4F4F4",
            borderRadius: "25px",
            position: "relative",
          }}>
          <div className="mx-auto my-2">
            <h4
              style={{
                color: "#004750",
                fontSize: "32px",
                fontWeight: "700",
              }}>
              Cliente
            </h4>
          </div>
          <div className="  lg:flex block ">
            <div className="lg:flex block">
              <div className="block mx-auto">
                <div className="lg:flex block">
                  <div className="mt-2 mx-4">
                    <div className="mb-3">
                      <label className="form-label">Cliente</label>
                      <input
                        type="text"
                        className="form-control text-center"
                        value={`${dataClient.firstName} - ${dataClient.firstLastName}`}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="mt-2 mx-4">
                    <div className="mb-3">
                      <label className="form-label">Contacto</label>
                      <input
                        type="text"
                        className="form-control text-center"
                        value={dataClient.email}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="mt-2 mx-4">
                    <div className="mb-3">
                      <label className="form-label">Referencia</label>
                      <input
                        type="text"
                        className="form-control text-center"
                        value={dataProject.reference}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="mt-2 mx-4">
                    <div className="mb-3">
                      <label className="form-label">Revisión</label>
                      <input
                        type="text"
                        className="form-control text-center"
                        value={dataProject.review}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mx-auto my-3">
            <h4
              style={{
                color: "#004750",
                fontSize: "32px",
                fontWeight: "700",
              }}>
              Proyecto
            </h4>
          </div>
          <div className="lg:flex block ">
            <div className="lg:flex block">
              <div className="block mx-auto">
                <div className="lg:flex block">
                  <div className="mt-2 mx-4">
                    <div className="mb-3">
                      <label className="form-label">ID</label>
                      <input
                        type="text"
                        className="form-control text-center"
                        value={dataProject.id}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="mt-2 mx-4">
                    <div className="mb-3">
                      <label className="form-label">Nombre del proyecto</label>
                      <input
                        type="text"
                        className="form-control text-center"
                        value={dataProject.title}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="mt-2 mx-4">
                    <div className="mb-3">
                      <label className="form-label">m2</label>
                      <input
                        type="text"
                        className="form-control text-center"
                        value={dataProject.m2}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="mt-2 mx-4">
                    <div className="mb-3">
                      <label className="form-label">Ubicación</label>
                      <input
                        type="text"
                        className="form-control text-center"
                        value={dataProject.location}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>

                <div className="lg:grid lg:grid-cols-2 block">
                  <div className="mt-2 ml-4 mr-1">
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Breve descripción
                      </label>
                      <textarea
                        name="textarea"
                        className="form-control text-justify"
                        value={dataProject.description}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="mt-2 mx-4">
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Alcances del servicio a realizar
                      </label>
                      <textarea
                        name="textarea"
                        className="form-control"
                        value={dataProject.scope}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mx-auto">
            <h4
              style={{
                color: "#004750",
                fontSize: "32px",
                fontWeight: "700",
              }}>
              Honorarios
            </h4>
          </div>
          {concepts.map((concept, index) => {
            return (
              <Concepts
                key={index}
                id={index}
                concept={concept.concept}
                price={concept.price}
                unit={concept.unit}
                discount={concept.discount}
                imported={concept.imported}
                duration={concept.duration}
                billing={concept.billing}
                reference={concept.reference}
                review={concept.review}
                scope={concept.scope}
                products={products}
                concepts={concepts}
                setConcepts={setConcepts}
              />
            );
          })}
          {isModalVisible && (
          <ModalValueOffer  
            isModalVisible={isModalVisible}
            onDeleteClick={handleDeleteModalValue}
            values={values}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}/>
          )}
          {isModalTermsVisible && (
            <DropDownMenuTerms  
            isModalVisible={isModalTermsVisible}
            onDeleteClick={handleDeleteModalTerms}
            values={terms}
            selectedTerms={selectedTerms}
            setSelectedTerms={setSelectedTerms}/>
          )}

          <div className="block mb-10 mx-auto">
            <div className="block">
              <button
                type="button"
                className="btn rounded-pill ColorButtonMain mt-3 mx-auto ancho-200"
                onClick={() => handleOnChange()}>
                <div className="flex content-center items-center">
                  <img src={LogoAdd} alt="" className="w-5 mr-2 TouchGreen" />
                  Concepto
                </div>
              </button>
              {!isModalVisible && (
              <button
                type="button"
                className="btn rounded-pill ColorButtonMain mt-3 mx-auto ancho-200"
                onClick={() => handleModalValue()}>
                <div className="flex content-center items-center">
                  <img src={LogoAdd} alt="" className="w-5 mr-2 TouchGreen" />
                  Valores
                </div>
              </button>
              )}
              {!isModalTermsVisible && (
                <button
                type="button"
                className="btn rounded-pill ColorButtonMain mt-3 mx-auto ancho-200"
                onClick={() => handleModalTerms()}>
                <div className="flex content-center items-center">
                  <img src={LogoAdd} alt="" className="w-5 mr-2 TouchGreen" />
                  Terminos
                </div>
              </button>
              )}
              <div className="mt-4"/>
              <div className="mx-auto">
                <button
                  type="button"
                  className="btn rounded-pill ColorButtonMainGreen mt-2 m-auto lg:ml-0 ancho-200"
                  onClick={(e) => saveQuote(e)}
                >
                  Guardar
                </button>
              </div>
              <div className="mx-auto">
                <button
                  type="button"
                  className="btn rounded-pill ColorButtonMainGreen mt-2 m-auto lg:ml-0 ancho-200"
                  
                  onClick={() => {
                    //console.log("selectedValues" + selectedValues);
                    getValuesTexts();
                    redirectPdf(dataProject, true);
                  }}>
                  <div className="flex content-center items-center">
                    <img
                      src={LogoView}
                      alt=""
                      className="w-4 mr-2 TouchGreen"
                    />
                    Ver PDF
                  </div>
                </button>
              </div>

              <button
                type="button"
                className="btn rounded-pill ColorButtonMain mt-3 lg:mr-3 ancho-200"
                onClick={() => {
                  setShowModalCot(false);
                  if (conceptsProps) {
                    setConceptsProps();
                    setIdQuoteLocalStorage();
                  }
                }}>
                Cancelar
              </button>

              <button
                type="submit"
                className="btn rounded-pill ColorButtonMainInv mt-3 lg:ml-3 ancho-200"
                onClick={(e) => handleAddQuote(e)}>
                Enviar
              </button>
            </div>
          </div>

          <button
            type="button"
            style={{
              position: "absolute",
              top: "2rem",
              right: "2rem",
            }}
            onClick={() => {
              setShowModalCot(false);
              if (conceptsProps) {
                setConceptsProps();
                setIdQuoteLocalStorage();
              }
            }}
            title="Cerrar"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm dark:hover:bg-gray-600 dark:hover:text-white">
            <svg className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"></path>
            </svg>
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateQuote;

/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";

import { Document, Page, Text, View, Image, Font } from "@react-pdf/renderer";

import SocialNetworksLinks from "./SocialNetworksLinks";

import BackgroundPdf from "../../../images/pdf/BackgroundPdf.png";
import BackgroundEnd from "../../../images/pdf/BackgroundEnd.png";
import FooterPdf from "../../../images/pdf/FooterPdf.png";
import lineaDegradadaPdf from "../../../images/pdf/lineaDegradadaPdf.png";
import Logotype from "../../../images/pdf/LogoBnzeroPdf.png";

import { formatDate } from "../../../utils/tools";
import { getFees } from "../../../services/Quotes.service";
import { getScopes, getConcepts, getAllSocialNetworks } from "../../../services/Admin.service";
import GothamLight from "../../../fonts/Gotham-Font/GothamMedium.ttf";
import { getCoverActive } from "../../../services/Admin.service";
import moment from "moment";
import { url } from "../../../api";
import './pdf_styles.css'; 
import staticData from './staticData.json';

const { 
  celphone, 
  quienesSomos, 
  leed, 
  leedList, 
  reuniones, 
  propuestaExtra, 
  proteccionDatos,
  formasPago,
  politicasPago } = staticData;

function PDF({
  dataClient = {},
  dataProject = {},
  dataProducts = {},
  dataOwner = {},
  dataFees = [],
  termsAndConditions = [],
  selectedValues = [],
  selectedTerms = [],
}) {
  function convertToRoman(num) {
    var roman = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1,
    };
    var str = "";

    for (var i of Object.keys(roman)) {
      var q = Math.floor(num / roman[i]);
      num -= q * roman[i];
      str += i.repeat(q);
    }

    return str;
  }

  let [loading, setLoading] = useState(false);
  let [fees, setFees] = useState([]);
  let [scope, setScope] = useState();
  let [concept, setConcept] = useState();
  let [totalPrice, setTotalPrice] = useState(0);
  let [tipoMoneda, setTipoMoneda] = useState('');
  let [totalIVA, setTotalIVA] = useState(0);
  let [total, setTotal] = useState(0);
  let [backgroundSrcPT, setBackgroundSrcPT] = useState(BackgroundPdf);
  let [backgroundSrcCP, setBackgroundSrcCP] = useState(BackgroundPdf);
  let [networkIcons, setNetworkIcons] = useState([]);

  var pageIndex = 1;

  useEffect(() => {
    _getdata();
    //console.log("Concept data: " + fees);
    console.log(dataProject);
  }, [dataProducts, dataFees, selectedValues, selectedTerms]);

  useEffect(() => {
    const fetchNetworks = async () => {
      const res = await getAllSocialNetworks();
      //console.log("nets: " + JSON.stringify(res, null, 2));
      setNetworkIcons(res);
    };
    
    fetchNetworks();
  }, []);

  function getNumbersInString(string) {
    if(String(string).length > 0 && typeof string === "string"){
      var tmp = string.split("");
      var map = tmp.map(function(current) {
        if (!isNaN(parseInt(current))) {
          return current;
        }
      });
    
      var numbers = map.filter(function(value) {
        return value != undefined;
      });
    
      return numbers.join("");
    }else return string;
  }

  async function _getdata() {
    
    let arrayTemp = [];
    let tipoMoneda = '';
    dataProducts.map((res) => {
      dataFees.map((res2) => {
        if (res.ref === res2.reference) {
          tipoMoneda = res.price.charAt(0);
          arrayTemp.push({
            id: res.id,
            ...res,
            ...res2,
            CONCEPTOS: res.concept,
            REF: res.ref,
            MESES: getNumbersInString(res2.duration),
            UNIDAD: res2.unit,
            VALOR: getNumbersInString(res2.price),
            DESCUENTO: getNumbersInString(res2.discount),
            IVA: Number(res2.imported * 0.16).toFixed(2),
            TOTAL: Number(res2.imported + Number(res2.imported * 0.16)).toFixed(2),
            PAGO: res2.billing,
            tipoMoneda
          });
        }
      });
      let sumPrice = 0;
      for (let i = 0; i < arrayTemp.length; i++) {
        sumPrice += parseInt(arrayTemp[i].VALOR);
      }
      let sumIVA = 0;
      for (let i = 0; i < arrayTemp.length; i++) {
        sumIVA += parseInt(arrayTemp[i].IVA);
      }
      let sumTotal = 0;
      for (let i = 0; i < arrayTemp.length; i++) {
        sumTotal += parseInt(arrayTemp[i].TOTAL);
      }
      setTipoMoneda(tipoMoneda);
      setTotalPrice(sumPrice);
      setTotalIVA(sumIVA);
      setTotal(sumTotal);
      setFees(arrayTemp);
      //console.log('Fees:', arrayTemp);
    });
    
    await getScopes().then((data) => {
      setScope(data.data);
      //console.log('Scopte:', data.data);
    });
    await getConcepts().then((data) => {    
      setConcept(data.data);
      //console.log('Concepts:', data.data);
    });

    // Llamadas asíncronas a getCoverActive para front y back
    try {
      const frontResponse = await getCoverActive("front");
      const backResponse = await getCoverActive("back");

      const imageUrlFront = URL.createObjectURL(frontResponse);
      const imageUrlBack = URL.createObjectURL(backResponse);

      setBackgroundSrcPT(imageUrlFront);
      //console.log(imageUrlFront);
      setBackgroundSrcCP(imageUrlBack);
      //console.log(imageUrlBack);
      setLoading(true);
    } catch (error) {
      console.error('Error al obtener las imágenes:', error);
    }
    
  }

  function toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  Font.register({
    family: "Gotham",
    src: GothamLight,
  });

  //const termsActivated = [1, 2, 5, 7]; // Los IDs que quieres mostrar

  return (
    loading && (
      <Document>
        {/* -------------------------------- PAGINA 1 -------------------------------- */}
        <Page
          size="A4"
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              fontFamily: "Gotham",
            }}
          >
            {backgroundSrcPT ? (
              <Image
                src={backgroundSrcPT}
                style={{
                  position: "absolute",
                  height: "100vh",
                  width: "100%",
                  objectFit: "cover",
                  zIndex: 0,
                }}
              />
            ) : (
              <Image
                src={BackgroundPdf}
                style={{
                  objectFit: "cover",
                  position: "absolute",
                  zIndex: 0,
                }}
              />
            )}
            <View
              style={{
                marginTop: 50,
                justifyContent: "center",
                alignItems: "center",
                paddingHorizontal: "5%",
              }}
            >
              <Image src={Logotype} alt="Logo" style={{ width: "350px" }} />
              <Text style={{ fontSize: 12, color: "gray", marginTop: 20 }}>
                Creando un proyecto común para un cambio posible
              </Text>
              
            </View>

            <View
              style={{
                marginTop: 50,
                marginLeft: "60%",
                paddingHorizontal: "5%",
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  color: "gray",
                  marginBottom: 20,
                  textAlign: "right",
                  color: "#004750",
                }}
              >
                {moment().format("MMM DD")} | {moment().format("YYYY")}
              </Text>
              
            </View>

            <View
              style={{
                paddingVertical: 30,
                paddingHorizontal: 30,
                margin: 25,
                backgroundColor: "white",
                opacity: 0.4,
              }}
            >
              <View>
                <Text
                  style={{
                    fontSize: 12,
                    marginBottom: 15,
                    opacity: 1,
                    color: "#7B7B7B",
                  }}
                >
                  PRESUPUESTO
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    marginBottom: 20,
                    opacity: 1,
                    color: "#7B7B7B",
                  }}
                >
                  CxA CERTIFICACIÓN LEED NC 4.1
                </Text>
              </View>

              <View>
                <Text
                    style={{
                      fontSize: 14,
                      marginBottom: 20,
                      opacity: 1,
                      color: "#004750",
                      fontWeight: "bold"
                    }}
                  >
                    {dataProject?.title || "<Nombre de proyecto>"}
                </Text>
              </View>

              <View>
                <Image
                  src={lineaDegradadaPdf}
                  alt="Logo"
                  style={{ width: "100%", marginBottom: 20, opacity: 1 }}
                />
              </View>

              <View
                style={
                  {
                    // display: "flex",
                    // flexDirection: "row",
                    // justifyContent: "center",
                    // alignItems: "center",
                  }
                }
              >
                <View style={{}}>
                  <Text
                    style={{
                      fontSize: 13,
                      color: "#004750",
                      marginBottom: 20,
                      marginTop: 20,
                      opacity: 1,
                    }}
                  >
                    Dirigido a:
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#7B7B7B",
                      marginBottom: 20,
                      opacity: 1,
                    }}
                  >
                    {dataClient.firstName} {dataClient.firstLastName}
                  </Text>
                </View>

                <View style={{}}>
                  <Text
                    style={{
                      fontSize: 13,
                      color: "#004750",
                      marginBottom: 20,
                      marginTop: 20,
                      opacity: 1,
                    }}
                  >
                    Elaborado por:
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#7B7B7B",
                      marginBottom: 20,
                      opacity: 1,
                    }}
                  >
                    {dataOwner?.firstName || "No data"}{" "}
                    {dataOwner?.lastName || "No data"}
                  </Text>
                </View>

                {/* <View style={{}}>
                  <Text
                    style={{
                      fontSize: 13,
                      color: "#4E4E4E",
                      marginBottom: 20,
                      marginTop: 20,
                      opacity: 1,
                    }}
                  >
                    Guadalajara, Jalisco, México
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#7B7B7B",
                      marginBottom: 20,
                      opacity: 1,
                    }}
                  >
                    {formatDate(dataProject.createDate)}
                  </Text>
                </View> */}
              </View>
            </View>

            <View
              style={{
                marginTop: 200,
                marginLeft: "60%",
                paddingHorizontal: "5%",
              }}
            >
              {/*<Text
                style={{
                  fontSize: 12,
                  color: "gray",
                  marginBottom: 20,
                  textAlign: "right",
                  color: "#7B7B7B",
                }}
              >
                Oficinas
              </Text>*/}
              <Text
                style={{
                  fontSize: 12,
                  color: "gray",
                  marginBottom: 20,
                  textAlign: "right",
                  color: "#7B7B7B",
                }}
              >
                {dataProject?.location}
              </Text>
            </View>
          </View>
        </Page>

        {/* -------------------------------- PAGINA 2 -------------------------------- */}
        <Page
          size="A4"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: 50,
            backgroundColor: "white",
          }}
        >
          <View
            style={{
              fontFamily: "Gotham",
              marginHorizontal: "5%",
              marginBottom: 220,
            }}
          >
            {/* --------------------------------- HEADER --------------------------------- */}
            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Image
                  src={Logotype}
                  alt="Logo"
                  style={{ width: 100, marginBottom: 20, opacity: 1 }}
                />
                
              </View>
            </View>

            {/* ---------------------------------- BODY ---------------------------------- */}
            <View
              style={{
                backgroundColor: "white",
                // paddingTop: 200,
              }}
            >
              <Text
                className="textTitle"
                style={{ marginBottom: 20, fontSize: 14 }}
              >
                PROPUESTA TÉCNICA
              </Text>

              <View>
                <Text
                  className="textSubtitle"
                  style={{ marginBottom: 20, fontSize: 9, paddingLeft: 20 }}
                >
                  {pageIndex++}. ¿QUIÉNES SOMOS?
                </Text>
                    {quienesSomos.map((texto, index) => (
                    <Text 
                      key={index} 
                      style={{ 
                        fontSize: 9, 
                        lineHeight: 1.3, 
                        marginBottom: 10,
                        color: "#7B7B7B", 
                        textAlign: 'justify' }} // Espacio de 10 unidades
                    >
                      {texto}
                    </Text>
                  ))}
                
              </View>

              <View style={{ marginHorizontal: '5%', marginBottom: 20 }}> {/* Contenedor principal */}
                <Text
                  className="textSubtitle"
                  style={{ marginBottom: 20, fontSize: 9, paddingLeft: 20 }}
                >
                  {pageIndex++}. ANTECEDENTES
                </Text>

                {/* Elemento 1 */}
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10, // Espacio entre los elementos
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Text style={{ fontSize: 10, width: 100 }}>Empresa:</Text> {/* Ancho fijo */}
                  <Text
                    style={{
                      fontSize: 10,
                      color: "#7B7B7B",
                      paddingLeft: 10,
                      flex: 1, // Hace que este texto ocupe el espacio restante
                      lineHeight: 1,
                    }}
                  >
                    {dataClient.company ? dataClient.company : "Empresa ejemplo"}
                  </Text>
                </View>

                {/* Elemento 2 */}
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10, // Espacio entre los elementos
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Text style={{ fontSize: 10, width: 100 }}>Superficie:</Text> {/* Ancho fijo */}
                  <Text
                    style={{
                      fontSize: 10,
                      color: "#7B7B7B",
                      paddingLeft: 10,
                      flex: 1, // Hace que este texto ocupe el espacio restante
                      lineHeight: 1,
                    }}
                  >
                    {dataProject?.m2}
                  </Text>
                </View>

                {/* Elemento 3 */}
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10, // Espacio entre los elementos
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Text style={{ fontSize: 10, width: 100 }}>Ubicación:</Text> {/* Ancho fijo */}
                  <Text
                    style={{
                      fontSize: 10,
                      color: "#7B7B7B",
                      paddingLeft: 10,
                      flex: 1, // Hace que este texto ocupe el espacio restante
                      lineHeight: 1,
                    }}
                  >
                    {dataProject.location}
                  </Text>
                </View>
              </View>

              <View>
                <Text
                  className="textSubtitle"
                  style={{ marginBottom: 20, fontSize: 9, paddingLeft: 20 }}
                >
                  {pageIndex++}. ¿POR QUÉ LEED®?
                </Text>
                    {leed.map((texto, index) => (
                    <Text 
                      key={index} 
                      style={{ 
                        fontSize: 9, 
                        lineHeight: 1.3, 
                        marginBottom: 10,
                        color: "#7B7B7B", 
                        textAlign: 'justify' }} // Espacio de 10 unidades
                    >
                      {texto}
                    </Text>
                  ))}

                <View
                   style={{
                    marginBottom: 20,
                    alignItems: 'left',
                  }}
                >
                  <Text style={{ fontSize: 10 }}>
                    Categorias eveluadas:
                  </Text>
                </View>
                <View style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between', // Espacio entre los elementos
                  }}>
                  {leedList.map((item, index) => (
                    <View key={index} style={{
                      width: '30%', // Ancho del 30% para 3 columnas
                      height: 10, // Altura de los elementos del grid
                      alignItems: 'left',
                      marginBottom: 2, // Espacio entre filas
                    }}>
                      <Text style={{
                        fontSize: 8,
                        color: "#7B7B7B",
                      }}>• {item}</Text>
                    </View>
                  ))}
                </View>
                
              </View>
            </View>
          </View>
          {/* --------------------------------- FOOTER --------------------------------- */}
          <View style={{ position: "absolute", bottom: 10 }}>
            <Image
              src={FooterPdf}
              style={{
                position: "absolute",
                display: "block",
                height: 80,
                minHeight: 80,
                minWidth: 650,
                width: 650,
              }}
            />
            <View style={{ marginTop: 20, marginLeft: "15%" }}>
              <Text style={{ fontSize: 10, color: "#01515C", lineHeight: 1.3 }}>
                {celphone}
              </Text>
            </View>
          </View>
        </Page>

        {/* -------------------------------- PAGINA 3 -------------------------------- */}
        <Page
          size="A4"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: 50,
            paddingBottom: 50,
            // justifyContent: "center",
            //alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <View
            style={{
              fontFamily: "Gotham",
              marginHorizontal: "5%",
              marginBottom: 95,
            }}
          >
            {/* --------------------------------- HEADER --------------------------------- */}
            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Image
                  src={Logotype}
                  alt="Logo"
                  style={{ width: 100, marginBottom: 20, opacity: 1 }}
                />
                
              </View>
            </View>

            {/* ---------------------------------- BODY ---------------------------------- */}
            <View
              style={{
                backgroundColor: "white",
                // paddingTop: 200,
              }}
            >
              
              <View>
                <Text
                  style={{ marginBottom: 20, fontSize: 9, paddingLeft: 20, paddingTop: 25 }}
                >
                  {pageIndex++}. ALCANCES:
                </Text>
                {fees.length > 0 && fees.filter(item => !item.isExtra).map(item => (
                <>
                  <View style={{ margin: 5 }}>
                      <View style={{ flexDirection: 'row', flexWrap: 'wrap'}}>
                        <View style={{flex: item.percentage1 / 100 + .5, border: "1px solid #7B7B7B", padding: 13, alignItems: 'left', backgroundColor: '#CBE990' 
                        }}>
                          <Text style={{ lineHeight: 1,fontSize: 10 }}>{item.title.replace(/\n/g, ' ')}</Text>
                        </View>
                        <View style={{ flex: item.percentage2 / 100 + .5, border: "1px solid #7B7B7B",  padding: 13, alignItems: 'left', backgroundColor: '#CBE990' }}>
                          <Text style={{ lineHeight: 1,fontSize: 10 }}>Entregables</Text>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                        <View style={{ flex: item.percentage1 / 100 + .5, border: "1px solid #7B7B7B", padding: 10, alignItems: 'justify' }}>
                          <Text style={{
                            fontSize: 9, 
                            textAlign: "left",
                            color: '#7B7B7B',
                            marginTop: 10, // Espacio entre el nombre y la descripción
                            lineHeight: 1,
                          }}>
                            {item.CONCEPTOS.replace(/\n/g, ' ')}
                          </Text>
                        </View>
                        <View style={{ flex: item.percentage2 / 100 + .5, border: "1px solid #7B7B7B", padding: 10, alignItems: 'center' }}>
                        <Text style={{
                            fontSize: 9, 
                            textAlign: "left",
                            color: '#7B7B7B',
                            marginTop: 10, // Espacio entre el nombre y la descripción
                            lineHeight: 1,
                          }}>
                            {item.scope.replace(/\n/g, ' ')}
                          </Text>
                        </View>
                      </View>
                  </View>  
                  
                </>
                ))}

              </View>


              <View>
                {fees.length > 0 && fees.some(item => item.isExtra) && (
                  <Text
                  style={{ marginBottom: 20, fontSize: 9, paddingLeft: 20, paddingTop: 25 }}
                >
                  {pageIndex++}. ALCANCES EXTRA:
                </Text>
                )}
                
                {fees.length > 0 && fees.filter(item => item.isExtra).map(item => (
                <>
                  <View style={{ margin: 5 }}>
                      <View style={{ flexDirection: 'row' }}>
                        <View style={{ flex: item.percentage1 / 100 + .5, border: "1px solid #7B7B7B",  padding: 13, alignItems: 'left', backgroundColor: '#CBE990' }}>
                          <Text style={{ lineHeight: 1,fontSize: 10 }}>{item.title.replace(/\n/g, ' ')}</Text>
                        </View>
                        <View style={{ flex: item.percentage2 / 100 + .5, border: "1px solid #7B7B7B",  padding: 13, alignItems: 'left', backgroundColor: '#CBE990' }}>
                          <Text style={{ lineHeight: 1,fontSize: 10 }}>Entregables</Text>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row' }}>
                        <View style={{ flex: item.percentage1 / 100 + .5, border: "1px solid #7B7B7B", padding: 10, alignItems: 'justify' }}>
                          <Text style={{
                            fontSize: 9, 
                            textAlign: "left",
                            color: '#7B7B7B',
                            marginTop: 10, // Espacio entre el nombre y la descripción
                            lineHeight: 1,
                          }}>
                            {item.CONCEPTOS.replace(/\n/g, ' ')}
                          </Text>
                        </View>
                        <View style={{ flex: item.percentage2 / 100 + .5, border: "1px solid #7B7B7B", padding: 10, alignItems: 'center' }}>
                        <Text style={{
                            fontSize: 9, 
                            textAlign: "left",
                            color: '#7B7B7B',
                            marginTop: 10, // Espacio entre el nombre y la descripción
                            lineHeight: 1,
                          }}>
                            {item.scope.replace(/\n/g, ' ')}
                          </Text>
                        </View>
                      </View>
                  </View>  
                  
                </>
                ))}

              </View>
              
              <Text
                  className="textSubtitle"
                  style={{ marginBottom: 20, fontSize: 9, paddingLeft: 20, paddingTop: 25 }}
                >
                  {pageIndex++}. REUNIONES Y VISITAS A TERRENO
              </Text>
              
              {reuniones.map((texto, index) => (
                    <Text 
                      key={index} 
                      style={{ 
                        fontSize: 9, 
                        lineHeight: 1.3, 
                        marginBottom: 10,
                        color: "#7B7B7B", 
                        textAlign: 'justify' }} // Espacio de 10 unidades
                    >
                      {texto}
                    </Text>
                  ))}

              {Array.isArray(selectedTerms) && selectedTerms.length > 0 && (
                <>
                  <Text
                    className="textSubtitle"
                    style={{ marginBottom: 20, fontSize: 9, paddingLeft: 20, paddingTop: 25 }}
                  >
                    {pageIndex++}. VALOR AGREGADO BNZERO (INCLUIDO EN LA PROPUESTA):
                  </Text>            

                  {selectedValues.map(item => (
                    <>
                      <Text style={{
                        fontSize: 12,
                        textAlign: "left",
                        color: '#7B7B7B',
                        fontWeight: 'bold',
                        marginTop: 10,
                      }}>
                        {item.name}
                      </Text>
                      <Text style={{
                        fontSize: 9, 
                        textAlign: "left",
                        color: '#7B7B7B',
                        marginTop: 10, // Espacio entre el nombre y la descripción
                        lineHeight: 1,
                      }}>
                        {item.description.replace(/\n/g, ' ')}
                      </Text>
                      {/* Añadir espacio adicional después de la última línea */}
                      <Text style={{ fontSize: 1, marginTop: 10 }}>&nbsp;</Text>
                    </>
                  ))}
                </>
              )}

              
            </View>
          </View>
          {/* --------------------------------- FOOTER --------------------------------- */}
          <View style={{ position: "absolute", bottom: 10 }}>
            <Image
              src={FooterPdf}
              style={{
                position: "absolute",
                display: "block",
                height: 80,
                minHeight: 80,
                minWidth: 650,
                width: 650,
              }}
            />
            <View style={{ marginTop: 20, marginLeft: "15%" }}>
              <Text style={{ fontSize: 10, color: "#01515C", lineHeight: 1.3 }}>
                {celphone}
              </Text>
            </View>
          </View>
        </Page>

        {/* -------------------------------- PAGINA 4 -------------------------------- */}
        <Page
          size="A4"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: 50,
            // justifyContent: "center",
            // alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <View
            style={{
              fontFamily: "Gotham",
              marginHorizontal: "5%",
              marginBottom: 53,
            }}
          >
            {/* --------------------------------- HEADER --------------------------------- */}
            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Image
                  src={Logotype}
                  alt="Logo"
                  style={{ width: 100, marginBottom: 20, opacity: 1 }}
                />
                
              </View>
            </View>

            {/* ---------------------------------- BODY ---------------------------------- */}
            <View
              style={{
                backgroundColor: "white",
                // paddingTop: 200,
              }}
            >
              <View>
              <Text
                style={{
                  fontSize: 16,
                  marginBottom: 20,
                  marginTop: 20,
                  color: "#4E4E4E",
                }}
              >
                PROPUESTA ECONÓMICA
              </Text>
              <Text
                style={{ 
                  marginBottom: 20, 
                  fontSize: 9, 
                  paddingLeft: 20, 
                  paddingTop: 25,
                  paddingBottom: 25,
                  borderBottom: "1px solid #7B7B7B" }}
              >
                {pageIndex++}. PROPUESTA DE HONORARIOS{" "}
              </Text>
              <View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "1px solid #7B7B7B"
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "50%",
                      fontWeight: "800",
                      textAlign: "center"
                    }}
                  >
                    Conceptos
                  </Text>

                  <Text
                    style={{
                      fontSize: 8,
                      width: "16%",
                      fontWeight: "800",
                      lineHeight: 1.3,
                      textAlign: "center",
                    }}
                  >
                    VALOR
                  </Text>

                  <Text
                    style={{
                      fontSize: 8,
                      width: "16%",
                      fontWeight: "800",
                      lineHeight: 1.3,
                      textAlign: "center",
                    }}
                  >
                    VALOR + IVA
                  </Text>

                  <Text
                    style={{
                      fontSize: 8,     
                      width: "16%",
                      fontWeight: "800",
                      lineHeight: 1.3,
                      textAlign: "center",
                    }}
                  >
                    PAGOS
                  </Text>
                </View>
                
                {fees.filter(item => !item.isExtra).map((dat, index) => {
                  return (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#CBE990",
                        borderBottom: "1px solid #7B7B7B",
                        alignItems: 'center',
                      }}
                      key={index}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          borderRightStyle: "solid",
                          width: "50%",
                          paddingTop: 15,
                          paddingLeft: 8, 
                          textAlign: "left",
                          justifyContent: "center",
                          alignItems: 'center',
                          lineHeight: 1
                        }}
                      >
                        {dat.title}
                      </Text>

                      <Text
                        style={{
                          fontSize: 8,
                          borderRightStyle: "solid",
                          width: "16%",
                          fontWeight: "800",
                          paddingTop: 8,
                          textAlign: "center",
                        }}
                      >
                        {dat.tipoMoneda === '$' ? `${dat.tipoMoneda} ${dat.VALOR}` : `${dat.VALOR} ${dat.tipoMoneda}`}
                      </Text>

                      <Text
                        style={{
                          fontSize: 8,
                          borderRightStyle: "solid",
                          width: "16%",
                          fontWeight: "800",
                          paddingTop: 8,
                          textAlign: "center",
                        }}
                      >
                        {dat.tipoMoneda === '$' ? `${dat.tipoMoneda} ${dat.TOTAL}` : `${dat.TOTAL} ${dat.tipoMoneda}`}
                      </Text>
                      
                      <Text
                        style={{
                          fontSize: 8,
                          width: "16%",
                          fontWeight: "800",
                          lineHeight: 1,
                          paddingTop: 8,
                          textAlign: "center"
                        }}
                      >
                        {dat.PAGO}
                      </Text>
                    </View>
                  );
                })}

                {/* Calcular el total de los conceptos */}
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#00D1C2", // Color para el total
                    alignItems: 'center',
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "50%",
                      fontWeight: "800",
                      textAlign: "center"
                    }}
                  >
                    Total
                  </Text>

                  <Text
                    style={{
                      fontSize: 8,
                      width: "16%",
                      fontWeight: "800",
                      textAlign: "center",
                    }}
                  >
                    {/* Sumar los valores de VALOR */}
                    {fees.filter(acc => !acc.isExtra).reduce((acc, dat) => acc + (parseFloat(dat.VALOR) || 0), 0).toFixed(2)} {fees.length > 0 ? fees[0].tipoMoneda : ''}
                  </Text>

                  <Text
                    style={{
                      fontSize: 8,
                      width: "16%",
                      fontWeight: "800",
                      textAlign: "center",
                    }}
                  >
                    {/* Sumar los valores de TOTAL */}
                    {fees.filter(acc => !acc.isExtra).reduce((acc, dat) => acc + (parseFloat(dat.TOTAL) || 0), 0).toFixed(2)} {fees.length > 0 ? fees[0].tipoMoneda : ''}
                  </Text>
                  
                  {/* Eliminar la última columna que no suma */}
                </View>
              </View>
            </View>

            {fees.length > 0 && fees.some(item => item.isExtra) && (
              <>
              <Text
              style={{ 
                marginBottom: 20, 
                fontSize: 9, 
                paddingLeft: 20, 
                paddingTop: 25,
                borderBottom: "1px solid #7B7B7B",
                paddingBottom: 15 }}
                >
                  PROPUESTA EXTRAS
              </Text>
              
              <View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "1px solid #7B7B7B",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      width: "50%",
                      fontWeight: "800",
                      textAlign: "center"
                    }}
                  >
                    Conceptos
                  </Text>

                  <Text
                    style={{
                      fontSize: 8,
                      width: "16%",
                      fontWeight: "800",
                      lineHeight: 1.3,
                      textAlign: "center",
                    }}
                  >
                    VALOR
                  </Text>

                  <Text
                    style={{
                      fontSize: 8,
                      width: "16%",
                      fontWeight: "800",
                      lineHeight: 1.3,
                      textAlign: "center",
                    }}
                  >
                    VALOR + IVA
                  </Text>

                  <Text
                    style={{
                      fontSize: 8,     
                      width: "16%",
                      fontWeight: "800",
                      lineHeight: 1.3,
                      textAlign: "center",
                    }}
                  >
                    PAGOS
                  </Text>
                </View>
                
                {fees.filter(item => item.isExtra).map((dat, index) => {
                  return (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#CBE990",
                        borderBottom: "1px solid #7B7B7B",
                        alignItems: 'center',
                      }}
                      key={index}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          borderRightStyle: "solid",
                          width: "50%",
                          paddingTop: 15,
                          paddingLeft: 8, 
                          textAlign: "left",
                          justifyContent: "center",
                          alignItems: 'center',
                          lineHeight: 1
                        }}
                      >
                        {dat.title}
                      </Text>

                      <Text
                        style={{
                          fontSize: 8,
                          borderRightStyle: "solid",
                          width: "16%",
                          fontWeight: "800",
                          paddingTop: 8,
                          textAlign: "center",
                        }}
                      >
                        {dat.tipoMoneda === '$' ? `${dat.tipoMoneda} ${dat.VALOR}` : `${dat.VALOR} ${dat.tipoMoneda}`}
                      </Text>

                      <Text
                        style={{
                          fontSize: 8,
                          borderRightStyle: "solid",
                          width: "16%",
                          fontWeight: "800",
                          paddingTop: 8,
                          textAlign: "center",
                        }}
                      >
                        {dat.tipoMoneda === '$' ? `${dat.tipoMoneda} ${dat.TOTAL}` : `${dat.TOTAL} ${dat.tipoMoneda}`}
                      </Text>
                      
                      <Text
                        style={{
                          fontSize: 8,
                          width: "16%",
                          fontWeight: "800",
                          lineHeight: 1,
                          paddingTop: 8,
                          textAlign: "center"
                        }}
                      >
                        {dat.PAGO}
                      </Text>
                    </View>
                  );
                })}
              </View>

              <Text
              style={{ 
                marginBottom: 20, 
                fontSize: 9, 
                paddingLeft: 20, 
                paddingTop: 12,
                paddingBottom: 15,
                lineHeight: 1
              }}
                >
                  NOTA: Estos extras se decidirán acorde el Pre evaluación del proyecto, si no los requieren no se contratarán por lo que estos costos son para que tengan una referencia pero no son obligatorios.
              </Text>
              </>
            )}   

            {/* Usamos un contenedor View para iterar */}
            <View style={{ paddingLeft: 20, paddingTop: 20}}>
              {propuestaExtra.map((texto, index) => (
                <Text 
                  key={index} 
                  style={{ 
                    fontSize: 9, 
                    lineHeight: 1.3, 
                    marginBottom: 10,
                    color: "#7B7B7B", 
                    textAlign: 'justify'
                  }}
                >
                  • {texto}
                </Text>
              ))}
            </View> 

            <View>
              <Text
                style={{ marginBottom: 20, fontSize: 9, paddingLeft: 20, paddingTop: 25 }}
              >
                {pageIndex++}. FORMAS DE PAGO - ASESORÍAS{" "}
              </Text>

              <View style={{ paddingLeft: 20}}>
              {formasPago.map((texto, index) => (
                <Text 
                  key={index} 
                  style={{ 
                    fontSize: 9, 
                    lineHeight: 1.3, 
                    marginBottom: 10,
                    color: "#7B7B7B", 
                    textAlign: 'justify'
                  }}
                >
                  • {texto}
                </Text>
              ))}
              </View> 
            </View>

            <View>
              <Text
                style={{ marginBottom: 20, fontSize: 9, paddingLeft: 20, paddingTop: 25 }}
              >
                Política de pagos y cobros:
              </Text>

              <View style={{ paddingLeft: 20}}>
              {politicasPago.map((texto, index) => (
                <Text 
                  key={index} 
                  style={{ 
                    fontSize: 9, 
                    lineHeight: 1.3, 
                    marginBottom: 10,
                    color: "#7B7B7B", 
                    textAlign: 'justify'
                  }}
                >
                  • {texto}
                </Text>
              ))}
              </View> 
            </View>      

            </View>
          </View>
          {/* --------------------------------- FOOTER --------------------------------- */}
          <View style={{ position: "absolute", bottom: 10 }}>
            <Image
              src={FooterPdf}
              style={{
                position: "absolute",
                display: "block",
                height: 80,
                minHeight: 80,
                minWidth: 650,
                width: 650,
              }}
            />
            <View style={{ marginTop: 20, marginLeft: "15%" }}>
              <Text style={{ fontSize: 10, color: "#01515C", lineHeight: 1.3 }}>
                {celphone}
              </Text>
            </View>
          </View>
        </Page>

        {/* -------------------------------- PAGINA 5 -------------------------------- */}
        <Page
          size="A4"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: 50,
            // justifyContent: "center",
            // alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <View
            style={{
              fontFamily: "Gotham",
              marginHorizontal: "5%",
              marginBottom: 29,
            }}
          >
            {/* --------------------------------- HEADER --------------------------------- */}
            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Image
                  src={Logotype}
                  alt="Logo"
                  style={{ width: 100, marginBottom: 20, opacity: 1 }}
                />
                
              </View>
            </View>

            {/* ---------------------------------- BODY ---------------------------------- */}
            <View
              style={{
                backgroundColor: "white",
                // paddingTop: 200,
              }}
            >
              <View>
                <Text
                  style={{ marginBottom: 20, fontSize: 9, paddingLeft: 20, paddingTop: 25 }}
                >
                  {pageIndex++}. TÉRMINOS Y CONDICIONES
                </Text>

                <View
                  style={{
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  {termsAndConditions
                  .filter(term => selectedTerms.includes(term.id)) // Filtra los términos que tienen los IDs especificados
                  .map((term) => {
                    return (
                      <View
                        style={{ flexDirection: "row", marginBottom: 8 }}
                        key={term.id}
                      >
                        <Text
                          style={{
                            fontSize: 8,
                            fontWeight: "light",
                            width: "5%",
                            lineHeight: 1.3,
                            color: "#7B7B7B",
                          }}
                        >
                          {convertToRoman(term.idOrder)}.
                        </Text>
                        <Text
                          style={{
                            fontSize: 8,
                            width: "95%",
                            textAlign: "justify",
                            lineHeight: 1.3,
                            color: "#7B7B7B",
                          }}
                        >
                          {term.description.trim().replace(/\n/g, ' ')}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              </View>

              <View>
                <Text
                  style={{ marginBottom: 20, fontSize: 9, paddingLeft: 20, paddingTop: 25 }}
                >
                  {pageIndex++}. CONFIDENCIALIDAD
                </Text>

                <Text
                  style={{ fontSize: 8, lineHeight: 1.3, color: "#7B7B7B" }}
                >
                  Esta oferta incluye información estratégica y confidencial de bnzero, y no debe ser distribuida a personas que no
                  estén directamente involucradas en la evolución y/o decisión de la oferta objeto del presente documento.
                </Text>
              </View>

              <View>
                <Text
                  style={{ marginBottom: 20, fontSize: 9, paddingLeft: 20, paddingTop: 25 }}
                >
                  {pageIndex++}. PROTECCIÓN DE LOS DATOS
                </Text>

                {/* Usamos un contenedor View para iterar los textos */}
                <View style={{ paddingTop: 10 }}>
                  {proteccionDatos.map((texto, index) => (
                    <Text 
                      key={index} 
                      style={{ 
                        fontSize: 9, 
                        lineHeight: 1.3, 
                        marginBottom: 10,
                        color: "#7B7B7B", 
                        textAlign: 'justify'
                      }}
                    >
                      {texto}
                    </Text>
                  ))}
                </View>
              </View>

            </View>
          </View>
          {/* --------------------------------- FOOTER --------------------------------- */}
          <View style={{ position: "absolute", bottom: 10 }}>
            <Image
              src={FooterPdf}
              style={{
                position: "absolute",
                display: "block",
                height: 80,
                minHeight: 80,
                minWidth: 650,
                width: 650,
              }}
            />
            <View style={{ marginTop: 20, marginLeft: "15%" }}>
              <Text style={{ fontSize: 10, color: "#01515C", lineHeight: 1.3 }}>
                {celphone}
              </Text>
            </View>
          </View>
        </Page>

        {/* -------------------------------- PAGINA 6 -------------------------------- */}

        <Page
          size="A4"
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              fontFamily: "Gotham",
              position: "relative", height: "100%", width: "100%"
            }}
          >
            {backgroundSrcCP ? (
              <Image
                src={backgroundSrcCP}
                style={{
                  position: "absolute",
                  height: "100vh",
                  width: "100%",
                  objectFit: "cover",
                  zIndex: 0,
                }}
              />
            ) : (
              <Image
                src={BackgroundEnd}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 0,
                  resizeMode: "contain"
                }}
              />
            )}
            <View
              style={{
                marginTop: 180,
                justifyContent: "center",
                alignItems: "center",
                paddingHorizontal: "5%",
              }}
            >
              <Image src={Logotype} alt="Logo" style={{ width: "350px" }} />
              <View
              style={{
                marginTop: 15,
                justifyContent: "center",
                alignItems: 'flex-end',
                marginLeft: 265,
                //backgroundColor: '#f0f0f0',
              }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    color: "gray",
                    marginBottom: 20,
                    textAlign: "left",
                  }}
                >
                  {moment().format("MMM DD")} | {moment().format("YYYY")}
                </Text>
                
              </View>
              
            </View>
            <View
              style={{
                marginTop: 180,
                justifyContent: "center",
                alignItems: 'flex-start',
                paddingHorizontal: "5%",
              }}
            >
              
              <View
              style={{
                marginTop: 250,
                justifyContent: "center",
                //alignItems: 'flex-end',
                marginLeft: 220,
                width: 120,
                //backgroundColor: '#f0f0f0',
              }}
              >
                <SocialNetworksLinks 
                  icons={networkIcons}
                />
              </View>
              
            </View>
            
          </View>
          
        </Page>
      </Document>
    )
  );
}

export default PDF;
